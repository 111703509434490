<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-between">
        <button
          class="compact ui button text-height"
          @click="refreshCommon"
        >
          <refresh-cw-icon
            size="12"
            class="custom-class line-top"
            :stroke-width="3"
          />
          Tải lại
        </button>
        <div>
          <filter-icon
            size="20"
            class="cursor-pointer mb-1"
            :stroke-width="2"
            @click="showFormFilter"
          />
        </div>
      </div>

      <advanced-search
        v-show="formFilterShow"
        ref="formFilter"
        class="mt-2"
        @advanced-search="filterDanhSachHoSo"
      />
      <good-table
        ref="hoSo"
        class="mt-1"
        :columns="getColumns"
        :selectable="false"
        :class="checkAllQuyen ? '' : 'fixed-column'"
        :rows="rows"
        :total="tongSoHoSo"
        @selected-item="onSelectRow"
        @column-filter="filterDanhSachHoSo"
        @page-change="pageChange"
      >
        <template
          slot="custom-row"
          slot-scope="props"
        >
          <div
            v-if="props.props.column.field === 'tenTrangThai'"
            class="custom-column"
          >
            <div>{{ formatDateTime(props.props.row.ngayNop) }}</div>
            <div>{{ formatDateTime(props.props.row.ngayHenTraKq) }}</div>
            <!-- eslint-disable vue/no-v-html -->
            <div v-html="props.props.row.tenLoaiTrangThai" />
            <!--eslint-enable-->
            <div
              v-if="props.props.row.thoiGianDenHanTraKQ"
              :class="props.props.row.thoiGianDenHanTraKQ.indexOf('Quá hạn') >= 0 ? 'text-red-cor' : 'text-green-cor'"
            >
              {{ props.props.row.thoiGianDenHanTraKQ }}
            </div>
          </div>
          <span v-if="props.props.column.field == 'code'">
            <a
              href="javascript:;"
              @click="detailHoSo(props.props.row)"
            >{{ `${props.props.row.code}` }}</a>
            <div>
              <cloud-icon
                size="15"
                class="custom-class"
              /> {{ props.props.row.kieuTiepNhan }}
            </div>
            <div v-if="props.props.row.dichVuChuyenPhatKetQua">
              <send-icon
                size="15"
                class="custom-class"
              /> Dịch vụ chuyển phát kết quả
            </div>
          </span>
          <div
            v-else-if="props.props.column.field === 'tenLoaiTrangThai'"
            class="custom-column"
          >
            <div v-if="props.props.row.tenTrangThai && !props.props.row.tenTrangThai.indexOf('Kết thúc')">
              <div class="text-yellow-cor">
                {{ props.props.row.tenTrangThai }}
              </div>

            </div>
            <div v-else>
              <div>{{ formatDateTime(props.props.row.thoiGianBatDauTrangThai) }}</div>
              <div>{{ formatDateTime(props.props.row.thoiGianKetThucTrangThai) }}</div>
              <div class="text-yellow-cor">
                {{ props.props.row.tenTrangThai }}
              </div>
              <div
                v-if="props.props.row.thoiGianDenHanKetThucTrangThai"
                :class="props.props.row.thoiGianDenHanKetThucTrangThai.indexOf('Còn hạn') >= 0 ? 'text-green-cor' : 'text-red-cor'"
              >
                {{ props.props.row.thoiGianDenHanKetThucTrangThai }}
              </div>
            </div>

          </div>
          <div v-else-if="props.props.column.field === 'chuHoSo_HoVaTen'">
            <div v-if="props.props.row.chuHoSo_HoVaTen">
              <user-icon size="14" /> {{ props.props.row.chuHoSo_HoVaTen }}
            </div>
            <div
              v-if="props.props.row.chuHoSo_DiaChiDayDu"
              class="text-top word-break"
            >
              <map-pin-icon size="14" /> {{ props.props.row.chuHoSo_DiaChiDayDu }}
            </div>
          </div>
          <div v-else-if="props.props.column.field === 'tenThuTucHanhChinh'">
            {{ props.props.row.tenThuTucHanhChinh }}
          </div>
          <div v-else-if="props.props.column.field === 'lePhi'">
            <span v-if="props.props.row.hoSoThuPhi">
              {{ props.props.row.hoSoThuPhi.lePhiHoSo }}
            </span>
            <span v-else>
              <span v-if="props.props.row.lePhi === 0" />
              <span v-else>
                {{ formatNumberToCost(props.props.row.lePhi) }}
              </span>
            </span>
          </div>
          <div v-else-if="props.props.column.field === 'phiHs'">
            <span v-if="props.props.row.hoSoThuPhi">
              {{ formatNumberToCost(props.props.row.hoSoThuPhi.phiHoSo) }}
            </span>
            <span v-else>
              <span v-if="props.props.row.phiHs === 0" />
              <span v-else>
                {{ formatNumberToCost(props.props.row.phiHs) }}
              </span>
            </span>
          </div>
        </template>
        <template
          slot="custom-filter"
          slot-scope="props"
        >
          <treeselect
            v-if="props.props.column.field === 'tenThuTucHanhChinh'"
            id="linhVuc"
            ref="tenThuTucHanhChinh"
            v-model="filter.dataTTHCId"
            v-format-v-select
            :default-options="danhSachThuTucHanhChinh"
            placeholder="Chọn thủ tục hành chính"
            :limit="0"
            multiple
            :limit-text="(count) => ` + ${count} lựa chọn`"
            no-options-text="Không có dữ liệu"
            no-results-text="Không có dữ liệu"
            loading-text="Đang tìm kiếm"
            :normalizer="normalizer"
            :match-keys="['label', 'label2']"
            class="vtreeselect-chooser"
            :load-options="onQueryChange"
            :async="true"
            :clear-on-select="true"
            :before-clear-all="clearTreeSelect"
            @select="$refs.tenThuTucHanhChinh.resetSearchQuery(); nextTickTreeSelect()"
            @deselect="nextTickTreeSelect()"
          >
            <label
              slot="option-label"
              slot-scope="{ node, shouldShowCount, count, labelClassName }"
              :class="labelClassName"
              :title="node.label"
            >
              {{ node.label }}
            </label>
          </treeselect>
          <treeselect
            v-else-if="props.props.column.field === 'tenLoaiTrangThai'"
            v-model="filter.trangThaiConHanQuaHan"
            v-format-v-select
            class="vtreeselect-chooser"
            :options="danhSachThoiHan"
            :limit="0"
            :limit-text="(count) => ` + ${count} lựa chọn`"
            no-options-text="Không có dữ liệu"
            no-results-text="Không có dữ liệu"
            placeholder="Chọn thời hạn"
            :clear-on-select="true"
            :before-clear-all="clearTreeSelect"
            @select="nextTickTreeSelect()"
            @deselect="nextTickTreeSelect()"
          />
          <treeselect
            v-else-if="props.props.column.field === 'tenTrangThai'"
            v-model="filter.loaiTrangThaiConHanQuaHan"
            v-format-v-select
            class="vtreeselect-chooser"
            :options="danhSachThoiHan"
            :limit="0"
            :limit-text="(count) => ` + ${count} lựa chọn`"
            no-options-text="Không có dữ liệu"
            no-results-text="Không có dữ liệu"
            placeholder="Chọn thời hạn"
            :clear-on-select="true"
            :before-clear-all="clearTreeSelect"
            @select="nextTickTreeSelect()"
            @deselect="nextTickTreeSelect()"
          />

        </template>
        <template
          slot="actions"
          slot-scope="props"
        >
          <div class="text-center">
            <navigation-icon
              v-if="checkQuyenGuiYeuCauThuPhi"
              v-b-tooltip.hover.v-secondary
              title="Gửi yêu cầu nộp phí,  lệ phí"
              size="16"
              class="custom-class cursor-pointer mr-1"
              @click="addNopPhi(props.props.row)"
            />
            <edit-icon
              v-if="checkQuyenCapNhatThongTinThuPhi"
              v-b-tooltip.hover.v-secondaryataForm
              title="Cập nhật thu phí hồ sơ"
              size="16"
              class="custom-class cursor-pointer mr-1"
              @click="updateHoSo(props.props.row)"
            />
          </div>
        </template>
      </good-table>
    </b-card>

    <common-modal
      ref="commomnModal"
      :title="title"
      :size="size"
      @handle-ok="handleOk"
      @handle-focus="handleFocusError"
    >
      <component
        :is="componentNameModal"
        ref="componentNameModal"
        :form-mode="formModeDialog"
        :data-form="dataInput"
      />
      <div
        v-if="isShowFooter"
        slot="footer"
      >
        <b-button
          v-if="componentNameModal === 'ThuPhiHoSoForm'"
          variant="primary"
          size="sm"
          class="mr-1"
          @click="handleOkSaveAndRequest"
        >
          Lưu và gửi yêu cầu
        </b-button>
        <b-button
          v-if="componentNameModal === 'ThuPhiHoSoForm'"
          variant="primary"
          size="sm"
          class="mr-1"
          @click="handleOkSave"
        >
          Lưu
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="openModal"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
    <common-modal
      ref="commonModal"
      :title="title"
      :size="size"
      @handle-ok="clickOk"
    >
      <component
        :is="componentName"
        ref="componentName"
      />
      <div
        v-if="isShow"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="clickOk"
        >
          Đồng ý
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="$refs.commonModal.hideModal()"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
    <common-modal
      ref="commonnnModal"
      title="Xác nhận"
      size="sm"
    >
      Bạn có xác nhận đóng quá trình nhập liệu này không?
      <div
        v-if="isShowter"
        slot="footer"
      >
        <b-button
          size="sm"
          variant="primary"
          class="mr-1"
          @click="$refs.commomnModal.hideModal() , $refs.commonnnModal.hideModal()"
        >
          Đồng ý
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="$refs.commonnnModal.hideModal()"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  BCard,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import GoodTable from '@/components/GoodTable.vue'
import AdvancedSearch from '@/components/AdvancedSearch.vue'
import END_POINTS from '@/api/endpoints'
import commonModal from '@/modules/xu-ly-ho-so/components/modal/CommonModal.vue'
import _debounce from 'lodash/debounce'
import _cloneDeep from 'lodash/cloneDeep'
import {
  formatDateTime, removeDiacritical, checkStatus, formatNumberToCost, compareObjects,
} from '@/utils/index'
import {
  FilterIcon,
  EditIcon,
  EyeIcon,
  RefreshCwIcon,
  CloudIcon,
  UserIcon,
  MapPinIcon,
  SendIcon,
  NavigationIcon,
} from 'vue-feather-icons'
import ThuPhiHoSoForm from '@/modules/xu-ly-ho-so/components/form/ThuPhiHoSoForm.vue'
import GuiYeuCauForm from '@/modules/xu-ly-ho-so/components/form/GuiYeuCauForm.vue'
import addCssSemantic from '@/mixins/mixins'
import { PERMISSION_NAME } from '@/constants/constants'
import { hasPermission } from '@/utils/permission-utils'

export default {
  components: {
    GoodTable,
    commonModal,
    UserIcon,
    MapPinIcon,
    RefreshCwIcon,
    EyeIcon,
    CloudIcon,
    SendIcon,
    BButton,
    BCard,
    NavigationIcon,
    ThuPhiHoSoForm,
    GuiYeuCauForm,
    EditIcon,
    FilterIcon,
    AdvancedSearch,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      faMagnifyingGlass,
      formModeDialog: 'NhanThuPhi',
      title: 'Thu phí hồ sơ',
      contentModal: '',
      isShow: false,
      isShowFooter: false,
      componentNameModal: '',
      componentName: '',
      size: '',
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
        },
        {
          label: 'Mã biên nhận',
          field: 'code',
          width: '150px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Tên thủ tục hành chính',
          field: 'tenThuTucHanhChinh',
          width: 'auto',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Chủ hồ sơ',
          field: 'chuHoSo_HoVaTen',
          width: '180px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Trạng thái',
          field: 'tenTrangThai',
          width: '150px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Chi tiết',
          field: 'tenLoaiTrangThai',
          width: '150px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Phí phải thu',
          field: 'phiHs',
          width: '120px',
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: 'Lệ phí phải thu',
          field: 'lePhi',
          width: '135px',
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: 'Thao tác',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
          field: 'actions',
          width: '100px',
        },
      ],
      tongSoHoSo: 0,
      isShowter: false,
      rows: [],
      isEmpty: false,
      dataItem: [],
      danhSachLinhVuc: [],
      selectedRows: [],
      filter: {
        dataTTHCId: [],
        dataLinhVucId: [],
        TrangThaiThuPhi: 0,
        fulltextsearch: '',
        chuHoSo: '',
        maHoSo: '',
        diaChiChuHoSo: '',
        thoiGianTiepNhanTu: '',
        thoiGianTiepNhanDen: '',
        thoiGianHenTraTu: '',
        thoiGianHenTraDen: '',
        trangThaiConHanQuaHan: null,
        loaiTrangThaiConHanQuaHan: null,
        trangThaiHoSo: [],
        loaiTrangThaiHoSo: [],
        navCode: this.$route.meta.code,
        pageNumber: 1,
        pageSize: 10,
      },
      danhSachThoiHan: [
        {
          id: 1,
          label: 'Còn hạn',
        },
        {
          id: 2,
          label: 'Quá hạn',
        },
      ],
      dataInput: {
        id: null,
        hoSoId: null,
        phiHoSo: 0,
        lePhiHoSo: 0,
        soDienThoai: null,
        lePhi_DaNop: true,
        phiHs_DaNop: true,
      },
      beginObject: {},
      formFilterShow: false,
      danhSachThuTucHanhChinh: [],
    }
  },
  computed: {
    ...mapGetters({
      permission: 'common/permissions',
    }),
    checkQuyenCapNhatThongTinThuPhi() {
      return hasPermission([PERMISSION_NAME.HO_SO_CAN_THU_PHI.CAP_NHAT_THONG_TIN_THU_PHI])
    },
    checkQuyenGuiYeuCauThuPhi() {
      return hasPermission([PERMISSION_NAME.HO_SO_CAN_THU_PHI.GUI_YEU_CAU_THU_PHI])
    },
    checkAllQuyen() {
      return [this.checkQuyenCapNhatThongTinThuPhi, this.checkQuyenGuiYeuCauThuPhi].every(item => item === false)
    },
    getColumns() {
      return this.checkAllQuyen ? this.columns.filter(item => item.field !== 'actions') : this.columns
    },
  },
  created() {
    this.filterDanhSachHoSo()
    this.getDanhSachThuTucHanhChinh()
  },
  methods: {
    formatDateTime,
    formatNumberToCost,
    openModal() {
      if (compareObjects(this.beginObject, this.dataInput)) {
        this.$refs.commomnModal.hideModal()
      } else {
        this.isShowter = true
        this.$refs.commonnnModal.showModal()
      }
    },
    onQueryChange({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleSearchQueryChange(searchQuery, callback)
      }
    },
    // eslint-disable-next-line func-names
    handleSearchQueryChange: _debounce(async function (query, callback) {
      const response = this.danhSachThuTucHanhChinh.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(query)) >= 0)
      callback(null, response)
    }, 100),
    handleOkSave() {
      this.isEmpty = false
      this.$refs.commomnModal.checkValidate()
    },
    handleOkSaveAndRequest() {
      this.isEmpty = true
      this.$refs.commomnModal.checkValidate()
    },
    getNumber(str) {
      const arr = str.split('')
      // eslint-disable-next-line prefer-const
      let out = []
      arr.forEach(item => {
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(item) === false) {
          out.push(item)
        }
      })
      return Number(out.join(''))
    },
    detailHoSo(data) {
      this.$router.push({ path: '/ho-so/chi-tiet', query: { id: data.id, name: this.$route.name } })
    },
    getDanhSachThuTucHanhChinh() {
      this.$axios
        .get(END_POINTS.THU_TUC_HANH_CHINH.COMBOBOX, false)
        .then(response => {
          if (response.data && response.data.succeeded) {
            this.danhSachThuTucHanhChinh = response.data.data
          }
        })
    },
    addNopPhi(data) {
      this.isShow = true
      this.title = 'Gửi yêu cầu thu phí,  lệ phí'
      this.componentName = 'GuiYeuCauForm'
      this.size = 'sm'
      this.dataInput = {
        id: data.id,
        hoSoId: data.id,
        phiHoSo: data.phiHs,
        lePhi_DaNop: data.lePhi_DaNop,
        phiHs_DaNop: data.phiHs_DaNop,
        lePhiHoSo: data.lePhi,
        soDienThoai: data.nguoiNop_SoDienThoai,

      }
      this.$refs.commonModal.showModal()
    },
    onSelectRow(rows) {
      this.selectedRows = rows
    },
    async handleOk() {
      if (this.componentNameModal === 'ThuPhiHoSoForm') {
        this.dataInput = {
          ...this.dataInput,
          hoSoId: this.dataItem.id,

        }
        this.dataInput.phiHoSo = this.$refs.componentNameModal.getDataPhi('phiInput') === 0 ? 0 : this.getNumber(this.$refs.componentNameModal.getDataPhi('phiInput'))
        this.dataInput.lePhiHoSo = this.$refs.componentNameModal.getDataPhi('lePhiInput') === 0 ? 0 : this.getNumber(this.$refs.componentNameModal.getDataPhi('lePhiInput'))
        this.$axios
          .post(
            END_POINTS.HO_SO_THU_PHI_LE_PHI.CAP_NHAT_LE_PHI_HO_SO,
            this.$trimObject(this.dataInput),
            false,
          )
          .then(res => {
            if (res) {
              if (this.isEmpty) {
                this.luuVaGuiYeuCau()
              } else {
                this.$toast.success(res.data.message)
              }
              this.filterDanhSachHoSo()
              this.$refs.commomnModal.hideModal()
            } else {
              this.$refs.componentNameModal.errorTrungMa = res.data.message
            }
          })
      }
    },
    luuVaGuiYeuCau() {
      this.componentName = 'GuiYeuCauForm'
      if (this.componentName === 'GuiYeuCauForm') {
        this.$axios
          .post(END_POINTS.HO_SO_THU_PHI_LE_PHI.YC_THU_PHI_HO_SO, this.dataInput, false)
          .then(res => {
            if (res) {
              this.$toast.success('Lưu và gửi yêu cầu nộp phí, lệ phí thành công!')
              this.filterDanhSachHoSo()
              this.$refs.commonModal.hideModal()
            }
          })
      }
    },
    clickOk() {
      if (this.componentName === 'GuiYeuCauForm') {
        this.$axios
          .post(END_POINTS.HO_SO_THU_PHI_LE_PHI.YC_THU_PHI_HO_SO, this.dataInput, false)
          .then(res => {
            if (checkStatus(res)) {
              this.filterDanhSachHoSo()
              this.$refs.commonModal.hideModal()
            }
          })
      }
    },
    filterDanhSachHoSo(param = {}) {
      this.filter = {
        ...this.filter,
        ...param,
      }
      let payload = {
        ...this.filter,
        trangThaiConHanQuaHan: this.filter.trangThaiConHanQuaHan || 0,
        loaiTrangThaiConHanQuaHan: this.filter.loaiTrangThaiConHanQuaHan || 0,
      }
      if (this.$refs.hoSo) {
        payload = {
          ...payload,
          ...this.$refs.hoSo.getCurrentPage(),
        }
      }
      if (this.filter.trangThaiConHanQuaHan) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
        }
      }
      if (this.filter.loaiTrangThaiConHanQuaHan) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
        }
      }
      if (this.filter.dataTTHCId.length) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
        }
      }
      if (this.$refs.formFilter) {
        payload = {
          ...payload,
          ...this.$refs.formFilter.getFormFilter(),
        }
      }
      if (param.chuHoSo_HoVaTen) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
          chuHoSo: param.chuHoSo_HoVaTen ? param.chuHoSo_HoVaTen.replace(/\s+/g, ' ').trim() : '',
        }
      }
      if (param.code) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
          maHoSo: param.code ? param.code.replace(/\s+/g, ' ').trim() : '',
        }
      }
      if (param.chuHoSo_DiaChiDayDu) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
          diaChiChuHoSo: param.chuHoSo_DiaChiDayDu ? param.chuHoSo_DiaChiDayDu.replace(/\s+/g, ' ').trim() : '',
        }
      }
      this.$axios
        .post(
          END_POINTS.TIEP_NHAN_HO_SO_BO_SUNG.DANH_SACH_HO_SO_BO_SUNG,
          this.$trimObject({
            ...payload,
          }),
          false,
        )
        .then(response => {
          if (response.data && response.data.succeeded) {
            this.rows = response.data.data
            this.tongSoHoSo = response.data.totalCount || 0
            this.$refs.hoSo.resetCurrentPage(payload.pageNumber)
          }
        })
    },
    pageChange() {
      const data = this.$refs.hoSo.getCurrentPage()
      this.payload = {
        ...this.filter,
        trangThaiConHanQuaHan: this.filter.trangThaiConHanQuaHan || 0,
        loaiTrangThaiConHanQuaHan: this.filter.loaiTrangThaiConHanQuaHan || 0,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      this.$axios
        .post(
          END_POINTS.TIEP_NHAN_HO_SO_BO_SUNG.DANH_SACH_HO_SO_BO_SUNG, this.payload, false,
        )
        .then(response => {
          if (response.data && response.data.succeeded) {
            this.rows = response.data.data
            this.tongSoHoSo = response.data.totalCount || 0
          }
        })
    },
    resetFilter() {
      this.filter = {
        dataTTHCId: [],
        dataLinhVucId: [],
        TrangThaiThuPhi: 0,
        fulltextsearch: '',
        chuHoSo: null,
        maHoSo: null,
        diaChiChuHoSo: '',
        thoiGianTiepNhanTu: '',
        thoiGianTiepNhanDen: '',
        thoiGianHenTraTu: '',
        thoiGianHenTraDen: '',
        trangThaiConHanQuaHan: null,
        loaiTrangThaiConHanQuaHan: null,
        trangThaiHoSo: [],
        loaiTrangThaiHoSo: [],
        navCode: this.$route.meta.code,
        pageNumber: 1,
        pageSize: 10,
      }
    },
    clearTreeSelect() {
      setTimeout(() => {
        this.nextTickTreeSelect()
      }, 200)
      return true
    },
    nextTickTreeSelect() {
      this.$nextTick(() => {
        this.filterDanhSachHoSo()
      })
    },
    refreshCommon() {
      if (this.$refs.formFilter) {
        this.$refs.formFilter.resetForm()
      }
      this.$refs.hoSo.$refs.vbTables.reset()
      this.resetFilter()
      this.filterDanhSachHoSo()
    },
    updateHoSo(data) {
      this.isShowFooter = true
      this.formModeDialog = 'NhanThuPhi'
      this.dataItem = data
      this.title = 'Cập nhật thu phí, lệ phí'
      this.size = 'lg'
      this.componentNameModal = 'ThuPhiHoSoForm'
      this.dataInput = {
        hoSoId: data.code,
        lePhi_DaNop: data.lePhi_DaNop,
        phiHs_DaNop: data.phiHs_DaNop,
        phiHoSo: data.phiHs.toString().replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
        lePhiHoSo: data.lePhi.toString().replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
        soDienThoai: data.nguoiNop_SoDienThoai,

      }
      this.selectedRows = [data]
      this.$nextTick(() => {
        this.beginObject = _cloneDeep(this.dataInput)
        this.$refs.commomnModal.showModal()
      })
    },
    setItemUpdate(item) {
      this.selectedRows = [item]
    },
    onDelete() {},
    showFormFilter() {
      this.formFilterShow = !this.formFilterShow
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
        label2: removeDiacritical(node.name),
      }
    },
    handleFocusError(first) {
      if (first) {
        const field = first[0]
        if (['nhomMauPhieu'].includes(field)) {
          this.$refs.componentNameModal.$refs[field].$el.querySelector('input').focus()
        } else {
          this.$refs.componentNameModal.$refs[field].focus()
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.input-icon {
  padding: 8px 0 8px 8px;
  border: 1px solid #d8d6de;
  border-right: none;
}

.input-group-icon {
  border-left: none;
}

.router-link-color {
  a {
    color: white;
  }
}

.custom-column {
  p {
    margin-bottom: 0;
  }
}
.text-top{
    margin-top:6px;
  }
</style>
