<template>
  <div class="text-left">
    Bạn có muốn nộp thu phí, lệ phí cho mã biên nhận này không?
  </div>
</template>
<script>
import {
} from 'bootstrap-vue'

export default {
  components: {},

  data() {
    return {}
  },
}
</script>
<style scoped lang="scss">
</style>
